/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { vendorAuth, logoutUser, ssoAuth, getCurrentJSONData } from "../auth";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { _t } from "../translate";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Images from "../images";

export function Auth({ component: SpecificComponent, errorComponent: ErrorComponent, adminRoute }) {
	function AuthenticationCheck(props) {
		let user = useSelector((state) => state.user);
		let vendor = useSelector((state) => state.vendor);
		const dispatch = useDispatch();

		if (window.location.host.indexOf("kermi") !== -1 && window.location.pathname === "/") {
			window.location = "https://portal.kermi.de/webcrm/de/heating/linear";
		}

		changeTabTitleAndIcon(vendor);

		let vendorId = Cookies.get("vendorId");
		let isHost = window.location.host.indexOf("localhost") === -1 && window.location.host.indexOf("herokuapp") === -1;
		let needVendorAuth = isHost && !vendor.secret;
		let needReAuth = vendor.secret;
		let invalidSession = !vendorId || vendorId === "undefined";
		if (invalidSession && (needVendorAuth || needReAuth)) {
			dispatch(vendorAuth(vendor.secret));
		}

		if (!user.user && ErrorComponent && (user?.userData?.name === "Error" || user.user === null)) {
			if (user?.userData?.message === "Request failed with status code 401" || user.user === null) {
				return <ErrorComponent {...props} error={{ title: _t("Nicht autorisiert"), text: _t("Nutzerlogin notwendig!") }} user={user} vendor={vendor} />;
			}
		}
		if (adminRoute && user.user && user.user.role !== "linear_admin") {
			return <ErrorComponent {...props} error={{ title: _t("Nicht autorisiert"), text: _t("Adminlogin notwendig!") }} user={user} vendor={vendor} />;
		}
		if (vendor && vendor.name === "Error") {
			if (ErrorComponent) {
				return <ErrorComponent {...props} error={{ title: _t("Nicht autorisiert"), text: _t("Der Hersteller wurde deaktiviert!") }} user={user} vendor={vendor} />;
			} else {
				return null;
			}
		}
		if (user.user && !user.jsonDataId && ErrorComponent && !adminRoute) {
			return (
				<ErrorComponent
					{...props}
					error={{ title: _t("Error"), text: _t("Keine Projektdaten vorhanden! Bitte kontaktieren Sie einen Admin") }}
					user={user}
					vendor={vendor}
				/>
			);
		}

		return <SpecificComponent {...props} user={user} vendor={vendor} />;
	}
	return <AuthenticationCheck />;
}

export function VendorAuth({ component: SpecificComponent }) {
	function AuthenticationCheck(props) {
		let user = useSelector((state) => state.user);
		let vendor = useSelector((state) => state.vendor);
		const dispatch = useDispatch();
		const navigate = useNavigate();
		const { id: secret } = useParams();

		if (secret === "henco") {
			navigate("/landing/ha8s7", { replace: true });
		}

		changeTabTitleAndIcon(vendor);

		dispatch(vendorAuth(secret)).then((response) => {
			if (response?.payload?.name) {
				if (user.user) {
					dispatch(logoutUser()).then((response) => {
						if (response.payload === "logged out!") {
							console.log("logged out");
						} else {
							console.error(response.payload);
						}
					});
				}
				navigate("/", { replace: true });
			}
		});

		return <SpecificComponent {...props} user={user} vendor={vendor} />;
	}
	return <AuthenticationCheck />;
}

export function SSOAuth({ component: SpecificComponent }) {
	function AuthenticationCheck(props) {
		let user = useSelector((state) => state.user);
		let vendor = useSelector((state) => state.vendor);
		const dispatch = useDispatch();
		const { token } = useParams();

		const navigate = useNavigate();
		useEffect(() => {
			// dispatch(logoutUser()).then(() => {
			dispatch(ssoAuth(token)).then((response) => {
				if (response?.payload?.vendor && response?.payload?.user) {
					dispatch({ type: "login_user", payload: response?.payload?.user });
					dispatch(getCurrentJSONData()).then((res) => {
						if (res.payload && res.payload.data) {
							navigate("/projectList");
						} else {
							var title = _t("Es existiert keine jsonData");
							var text = _t("Bitte kontaktieren Sie den Support");
							dispatch({ type: "error_modal", payload: { show: true, title: title, text: text } });
						}
					});
				} else {
					navigate("/error", {
						valid: true,
						title: _t("Nicht autorisiert"),
						text: _t("Das Authentifizierungstoken ist ungültig. bitte wenden Sie sich an den Administrator."),
					});
				}
			});
			// });
		}, []);

		return <SpecificComponent {...props} user={user} vendor={vendor} />;
	}
	return <AuthenticationCheck />;
}

export function ErrorAuth({ component: SpecificComponent }) {
	function AuthenticationCheck(props) {
		let user = useSelector((state) => state.user);
		let vendor = useSelector((state) => state.vendor);
		let location = useLocation();
		let valid = location.state?.valid;
		let title = location.state?.title;
		let text = location.state?.text;
		const navigate = useNavigate();
		if (!valid) {
			navigate("/");
		}
		return <SpecificComponent {...props} error={{ title, text }} user={user} vendor={vendor} noLayout={true} />;
	}
	return <AuthenticationCheck />;
}

function changeTabTitleAndIcon(vendor) {
	if (document.title !== vendor.name) {
		document.title = vendor?.name;
	}

	var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
	link.type = "image/x-icon";
	link.rel = "shortcut icon";
	if (link.href !== Images(vendor.postfix)?.logo_large) {
		if (document.title !== undefined && document.title.includes("COSMO")) {
			link.href = Images(vendor.postfix)?.favicon;
		} else {
			link.href = Images(vendor.postfix)?.logo_large;
		}
		document.getElementsByTagName("head")[0].appendChild(link);
	}
}
